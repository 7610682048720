import React from 'react';
import {Button, Card, DatePicker, Form, Input, notification, Select} from "antd";
import {useForm} from "antd/es/form/Form";
import axios from "axios";

const selectBefore = (
    <Form.Item name="title" noStyle initialValue={"mr"}>
        <Select
            popupMatchSelectWidth={true}
            options={[
                {label: "Mr", value: "mr"},
                {label: "Mrs", value: "mrs"},
                {label: "Miss", value: "miss"},
            ]}
        />
    </Form.Item>
);

function App() {
    const [form] = useForm();
    const [loading, setLoading] = React.useState(false);
    const [api, contextHolder] = notification.useNotification();

    const onFinish = (values) => {
        console.log(values)
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + "offer-letter";
        axios({
            method: 'post',
            url: url,
            data: {
                ...values,
                name: `${values.title} ${values.name}`,
                doj: values.doj.toISOString()
            },
            responseType: 'arraybuffer' // or 'blob'
        })
            .then((response) => {
                alert("Offer letter generated successfully")
            })
            .catch(() => {
                alert("Something went wrong")
            })
            .finally(() => setLoading(false));
    }


    return (
        <Card title={"Create offer letter"} className={"p-2"}>
            <Form form={form} layout={"vertical"} onFinish={onFinish}>
                <Form.Item
                    label={"Full Name"}
                    name={"name"}
                    rules={[{required: true, message: 'Please input your name!'}]}
                >
                    <Input addonBefore={selectBefore} type="text" className={"form-control"}/>
                </Form.Item>
                <Form.Item
                    label={"Date of Joining"}
                    name={"doj"}
                    rules={[{required: true, message: 'Please input your email!'}]}
                >
                    <DatePicker/>
                </Form.Item>
                <Form.Item
                    label={"Role"}
                    name={"role"}
                    rules={[{required: true, message: 'Please input your role!'}]}
                    initialValue={"data-analyst"}
                >
                    <Select
                        options={[
                            {label: "Data Analyst", value: "data-analyst"},
                        ]}
                    />
                </Form.Item>
            </Form>
            <Button loading={loading} disabled={loading} onClick={() => form.submit()} type={"primary"}>Submit</Button>
        </Card>
    );
}

export default App;